<template>
  <div class="entry" @mouseover="hover = true" @mouseleave="hover = false" @click="expanded = !expanded">
    <img :class="thumbnailCss" :src="thumbnail" alt="">
    <div class="content">
      <div>
        <h2 class="title">
          <router-link :to="url" class="link">{{ title }}</router-link>
        </h2>
        <p class="description">{{ truncatedDescription }}</p>
      </div>
      <div class="footer">
        {{ annotation }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    url: { type: String, required: true },
    thumbnail: { type: String, required: true },
    date: { type: String, required: true },
    annotation: { type: String, required: true }
  },
  computed: {
    truncatedDescription () {
      return this.expanded
        ? this.description
        : this.truncate(this.description, 300)
    },
    thumbnailCss () {
      return {
        thumbnail: true,
        'thumbnail--hover': this.hover || this.expanded
      }
    }
  },
  data () {
    return {
      hover: false,
      expanded: false
    }
  },
  methods: {
    truncate (text, maxLength) {
      const dots = '...'
      const effectiveLimit = maxLength - dots.length
      return text.length > effectiveLimit
        ? text.slice(0, effectiveLimit) + dots
        : text
    }
  }
}
</script>

<style scoped>
.entry {
    width: 90%;
    margin: 0 auto;
    display: flex;
}

.thumbnail {
  height: 140px;
  width: 140px;
  object-fit: cover;
  margin-right: 20px;
  filter: grayscale(1);
  opacity: 0.6;
  transition: filter 0.15s ease, opacity 0.15s ease;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.thumbnail--hover {
    filter: grayscale(0);
    opacity: 1;
}

.title {
    margin: 0;
}

.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.description {
    margin-top: 5px;
    color: #222;
}

.footer {
    color: #ccc;
    margin: 5px 0;
    font-size: 0.9rem;
    text-align: right;
}
</style>
