<template>
  <div class="container">
    <div class="links">
      <ul class="links-list">
        <li class="link-wrapper"><router-link to="/random" class="link">Wylosuj odcinek</router-link></li>
        <li class="link-wrapper"><router-link to="/about" class="link">O stronie</router-link></li>
        <li class="link-wrapper"><a href="https://komputeryk.pl/kontakt" class="link">Kontakt</a></li>
      </ul>
    </div>
    <div class="search-form" ref="search">
      <h1 class="title">Wyszukaj odcinek</h1>
      <label for="query" class="label">Podaj frazę, którą chcesz wyszukać</label>
      <div class="form-group">
        <input
          type="text"
          id="query"
          class="input"
          placeholder="Podaj frazę, którą chcesz wyszukać"
          v-model="query"
          v-debounce:500ms="search"
        >
        <button class="button" @click="search">Wyszukaj</button>
      </div>
      <div class="search-results" ref="results">
        <ol class="serch-results__list">
          <li class="list__item" v-for="(hit, index) in hits" :key="index">
            <SearchEntry
              :title="hit.title"
              :description="hit.description"
              :url="hit.url"
              :date="hit.date"
              :thumbnail="hit.thumbnail"
              :annotation="hit.annotation"
            />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../config'
import SearchEntry from '../components/SearchEntry'

export default {
  components: {
    SearchEntry
  },
  data () {
    return {
      query: this.$route.params.query ?? '',
      hits: []
    }
  },
  methods: {
    async search () {
      if (this.query.trim().length === 0) {
        this.$router.push('/')
        this.hits = []
        return
      }

      const query = encodeURI(this.query)
      const response = await axios.get(`${config.apiUrl}/search?q=${query}`)
      this.hits = response.data.hits.map(hit => ({
        title: hit.title,
        description: hit.description,
        url: `/${hit.series_id}/${hit.episode_id}`,
        date: hit.date,
        thumbnail: `${config.apiUrl}/${hit.series_id}/${hit.episode_id}/thumbnail`,
        annotation: hit.annotation
      }))
      this.$router.push(`/search/${query}`)
    }
  },
  mounted () {
    this.search()
  }
}
</script>

<style scoped>
.container {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    transition: translateY 500ms ease;
}

.title {
    margin: 0;
    padding: 0;
}

.search-form {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.label {
  visibility: hidden;
}

.input {
    width: 400px;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #ccc;
    border-right: none;
    text-align: center;
    outline: none;
}

.button {
    padding: 10px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #ccc;
    color: #444;
}

.button:hover {
    color: #111;
}

.search-results {
  margin-top: 20px;
}

.serch-results__list {
  margin: 0;
  padding: 0;
}

.list__item {
    padding: 20px 0;
    margin: 0;
    list-style-type: none;
    border-bottom: 1px solid #dfdfdf;
}

.list__item:last-child {
    border-bottom: none;
}

.links {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
}

.links-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.link-wrapper {
  padding: 5px 10px;
}

.link {
  color: #222;
  text-decoration: none;
  font-size: 0.95em;
}

.link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1500px) {
  .search-form {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .search-form {
    width: 100%;
  }
}
</style>
