<template>
  <router-view />
</template>

<style>
body, html {
  font-family: Roboto, sans-serif;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}
</style>
