import { createRouter, createWebHistory } from 'vue-router'

import SearchView from '../views/SearchView'
import RandomView from '../views/RandomView'
import VideoView from '../views/VideoView'
import NotFoundView from '../views/NotFoundView'

const routes = [
  { path: '/', name: 'Home', component: SearchView },
  { path: '/random', name: 'Random', component: RandomView },
  { path: '/search/:query', name: 'Search', component: SearchView },
  { path: '/:series/:episode', component: VideoView },
  { path: '/:path(.*)*', component: NotFoundView },
  { path: '/:path(.*)', component: NotFoundView }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
