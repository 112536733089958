<script>
import config from '../config'

export default {
  data () {
    return {
      series: this.$route.params.series,
      episode: this.$route.params.episode
    }
  },
  async created () {
    const url = `${config.apiUrl}/${this.series}/${this.episode}?skipCredits=1`
    window.location = url
  }
}
</script>

<style scoped>
    .video-frame {
        width: 100vw;
        height: 100vh;
        margin: 0;
        border: 0;
    }
</style>
