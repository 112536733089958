<template>
  <div class="container">
    <h1 class="title">Strona nie istnieje</h1>
    <p>
      Zabłądziłeś?
      <router-link to="/" class="link">Kliknij</router-link>,
      aby powrócić do strony głównej.
    </p>
  </div>
</template>

<style scoped>
  .title {
    margin: 0;
  }
  .container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .link {
    color: #2aa;
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
  }
</style>
