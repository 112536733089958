import { createApp } from 'vue'
import { vue3Debounce } from 'vue-debounce'
import easySpinner from 'vue-easy-spinner'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.directive('debounce', vue3Debounce({ lock: true }))
app.use(router)
app.use(easySpinner, { prefix: 'easy' })
app.mount('#app')
